<template>
  <div>
    <div class="text-h6 font-weight-medium mb-4">
      {{ $t("bulksms.sms.title") }}
    </div>
    <div class="mt-4">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('bulksms.sms.title')
    }
  },
  data: () => ({})
}
</script>