<template>
  <div>
    <sms></sms>
  </div>
</template>

<script>
import Sms from "./../../../components/bulksms/sms/index";
export default {
  data: () => ({
  }),
  components: {
    Sms,
  },
};
</script>